import { NavLink } from "@remix-run/react";
import { FC } from "react";

import InstagramLogo from "assets/media/logos/instagram-opt.svg";
import TiktokLogo from "assets/media/logos/tiktok-opt.svg";
import { useAtomValue } from "jotai";
import ReactGA from "react-ga4";
import { ReactSVG } from "react-svg";
import { INSTAGRAM_PROFILE_URL, TIKTOK_PROFILE_URL } from "~/constants/urls";
import { topBarThemeAtom } from "~/state/jotai";

export const Footer: FC<{}> = ({}) => {
	const theme = useAtomValue(topBarThemeAtom);

	return (
		<footer className="flex flex-col gap-4 w-full shrink-0 min-h-[50vh] py-10 pt-14 px-5">
			<div className="grid grid-cols-2 grid-rows-2 gap-y-2 gap-x-4 mb-10 h-full">
				{/* <div>
					<h5 className="mb-2">Services</h5>
					<ul className="text-white/50">
						<li>Ticketing</li>
					</ul>
				</div> */}
				<div>
					<h5 className="mb-2">Company</h5>
					<ul className="text-white/50">
						<li>
							{/* TODO: Add About us page */}
							<NavLink to={"/"}>About</NavLink>
						</li>
						{/* <li>Brand</li> */}
						{/* <li>
							<NavLink to={"/blog"}>Blog</NavLink>
						</li> */}
					</ul>
				</div>
				<div>
					<h5 className="mb-2">Support</h5>
					{/* Better name */}
					<ul className="text-white/50">
						{/* <li>Support</li> */}
						{/* <li>Brand</li> */}
						{/* <li>Community</li> */}
						<li>
							<a href="mailto:theunderground.ke@gmail.com">
								Contact
							</a>
						</li>
						<li>
							<NavLink to={"/feedback"}>Feedback</NavLink>
						</li>
					</ul>
				</div>

				<div>
					<h5 className="mb-2">Legal</h5>
					<ul className="text-white/50">
						<li>
							<NavLink to={"/terms"}>Terms</NavLink>
						</li>
						<li>
							<NavLink to={"/privacy"}>Privacy</NavLink>
						</li>
						{/* <li>
							<NavLink to={"/cookies"}>Cookies</NavLink>
						</li> */}
					</ul>
				</div>
			</div>
			<div className="flex gap-4">
				<a
					onClick={() =>
						ReactGA.event({
							category: "User",
							action: "Clicked Instagram Link via Footer",
						})
					}
					href={INSTAGRAM_PROFILE_URL}
					target="_blank"
				>
					<ReactSVG
						renumerateIRIElements={false}
						wrapper="svg"
						src={InstagramLogo}
						className={`h-6 w-6 ${theme.fill} ${theme.stroke} stroke-[2]`}
					/>
				</a>
				<a
					onClick={() =>
						ReactGA.event({
							category: "User",
							action: "Clicked Tiktok Link via Footer",
						})
					}
					href={TIKTOK_PROFILE_URL}
					target="_blank"
				>
					<ReactSVG
						renumerateIRIElements={false}
						wrapper="svg"
						src={TiktokLogo}
						className={`h-6 w-6 ${theme.fill} ${theme.stroke} stroke-[2]`}
					/>
				</a>
			</div>
			<div className="text-xs text-white/50">The Underground © 2023</div>
		</footer>
	);
};
